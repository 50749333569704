export { default as AndroidIcon } from './Android'
export { default as AppStoreIcon } from './AppStore'
export { default as FacebookIcon } from './Facebook'
export { default as InstagramIcon } from './Instagram'
export { default as XIcon } from './X'
export { default as YoutubeIcon } from './Youtube'
export { default as MasterCardIcon } from './MasterCard'
export { default as JCBIcon } from './JCB'
export { default as DinersClubIcon } from './DinersClub'
export { default as DiscoverIcon } from './Discover'
export { default as VisaIcon } from './Visa'
export { default as AmexIcon } from './Amex'

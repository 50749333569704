import React, { SVGProps } from 'react'

function FacebookIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='28'
      fill='none'
      viewBox='0 0 15 28'
      {...props}
    >
      <path
        fill='#fff'
        d='M9.91 6.321v3.942h4.875l-.772 5.309H9.911v12.232a18.329 18.329 0 01-5.416-.057V15.572H0v-5.31h4.495V5.44A5.417 5.417 0 019.912.022v.003c.009 0 .016-.003.025-.003h4.85v4.592h-3.17c-.94 0-1.705.764-1.705 1.706l-.001.001z'
      ></path>
    </svg>
  )
}

export default FacebookIcon

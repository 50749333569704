import React, { SVGProps } from 'react'

function JCBIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width || '780'}
      height={props.height || '500'}
      viewBox='0 0 780 500'
      {...props}
    >
      <linearGradient
        id='a'
        x1='908.72'
        x2='909.72'
        y1='313.21'
        y2='313.21'
        gradientTransform='matrix(132.87 0 0 323.02 -120270 -100930)'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0' stopColor='#007b40'></stop>
        <stop offset='1' stopColor='#55b330'></stop>
      </linearGradient>
      <linearGradient
        id='b'
        x1='908.73'
        x2='909.73'
        y1='313.21'
        y2='313.21'
        gradientTransform='matrix(133.43 0 0 323.02 -121080 -100920)'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0' stopColor='#1d2970'></stop>
        <stop offset='1' stopColor='#006dba'></stop>
      </linearGradient>
      <linearGradient
        id='c'
        x1='908.72'
        x2='909.72'
        y1='313.21'
        y2='313.21'
        gradientTransform='matrix(132.96 0 0 323.03 -120500 -100930)'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0' stopColor='#6e2b2f'></stop>
        <stop offset='1' stopColor='#e30138'></stop>
      </linearGradient>
      <path
        fill='#fff'
        d='M632.24 361.27c0 41.615-33.729 75.36-75.357 75.36h-409.13V138.75c0-41.626 33.73-75.371 75.364-75.371h409.12l-.001 297.89z'
      ></path>
      <path
        fill='url(#a)'
        d='M498.86 256.54c11.686.254 23.438-.516 35.077.4 11.787 2.199 14.628 20.043 4.156 25.887-7.145 3.85-15.633 1.434-23.379 2.113H498.86zm41.834-32.145c2.596 9.164-6.238 17.392-15.064 16.13h-26.77c.188-8.642-.367-18.022.272-26.209 10.724.302 21.547-.616 32.209.48 4.581 1.151 8.415 4.917 9.353 9.599zm64.425-135.9c.498 17.501.072 35.927.215 53.783-.033 72.596.07 145.19-.057 217.79-.47 27.207-24.582 50.848-51.601 51.391-27.045.11-54.094.017-81.143.047v-109.75c29.471-.152 58.957.309 88.416-.23 13.666-.858 28.635-9.875 29.271-24.914 1.609-15.104-12.631-25.551-26.151-27.201-5.197-.135-5.045-1.515 0-2.117 12.895-2.787 23.021-16.133 19.227-29.499-3.233-14.058-18.771-19.499-31.695-19.472-26.352-.179-52.709-.025-79.062-.077.17-20.489-.355-41 .283-61.474 2.088-26.716 26.807-48.748 53.446-48.27 26.287-.004 52.57-.004 78.851-.005z'
      ></path>
      <path
        fill='url(#b)'
        d='M174.74 139.54c.673-27.164 24.888-50.611 51.872-51.008 26.945-.083 53.894-.012 80.839-.036-.074 90.885.146 181.78-.111 272.66-1.038 26.834-24.989 49.834-51.679 50.309-26.996.098-53.995.014-80.992.041v-113.45c26.223 6.195 53.722 8.832 80.474 4.723 15.991-2.573 33.487-10.426 38.901-27.016 3.984-14.191 1.741-29.126 2.334-43.691v-33.825h-46.297c-.208 22.371.426 44.781-.335 67.125-1.248 13.734-14.849 22.46-27.802 21.994-16.064.17-47.897-11.642-47.897-11.642-.08-41.914.466-94.405.693-136.18z'
      ></path>
      <path
        fill='url(#c)'
        d='M324.72 211.89c-2.437.517-.49-8.301-1.113-11.646.166-21.15-.347-42.323.283-63.458 2.082-26.829 26.991-48.916 53.738-48.288h78.768c-.074 90.885.145 181.78-.111 272.66-1.039 26.834-24.992 49.833-51.683 50.309-26.997.102-53.997.016-80.996.042v-124.3c18.439 15.129 43.5 17.484 66.472 17.525 17.318-.006 34.535-2.676 51.353-6.67v-22.772c-18.953 9.446-41.233 15.446-62.243 10.019-14.656-3.648-25.295-17.812-25.058-32.937-1.698-15.729 7.522-32.335 22.979-37.011 19.191-6.008 40.107-1.413 58.096 6.398 3.854 2.018 7.766 4.521 6.225-1.921v-17.899c-30.086-7.158-62.104-9.792-92.33-2.005-8.749 2.468-17.273 6.211-24.38 11.956z'
      ></path>
    </svg>
  )
}

export default JCBIcon

import React, { SVGProps } from 'react'

function AppStoreIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='29'
      height='28'
      fill='none'
      viewBox='0 0 29 28'
      {...props}
    >
      <path
        fill='#fff'
        d='M.037 25.333A2.666 2.666 0 002.704 28h22.667a2.666 2.666 0 002.666-2.667V2.667A2.666 2.666 0 0025.37 0H2.704A2.666 2.666 0 00.037 2.667v22.666zm7.937-3.303a1.248 1.248 0 11-2.163-1.25l.894-1.544c1.006-.307 1.83-.069 2.474.712l-1.206 2.081v.001zm14.815-5.867a1.25 1.25 0 110 2.501h-1.813l1.225 2.118c.345.6.138 1.363-.456 1.707-.6.344-1.362.137-1.707-.456-2.055-3.556-3.593-6.232-4.625-8.007-1.045-1.812-.3-3.625.444-4.238.82 1.42 2.044 3.545 3.68 6.375h3.252zm-17.503-.001h3.188l4.088-7.075-1.28-2.212a1.252 1.252 0 01.456-1.707 1.252 1.252 0 011.706.457l.557.962.556-.962a1.249 1.249 0 112.163 1.25l-5.363 9.288h3.882c1.262 0 1.97 1.482 1.418 2.5H5.286c-.688 0-1.25-.562-1.25-1.25 0-.689.563-1.251 1.25-1.251z'
      ></path>
    </svg>
  )
}

export default AppStoreIcon

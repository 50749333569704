import React, { SVGProps } from 'react'

function InstagramIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='28'
      fill='none'
      viewBox='0 0 30 28'
      {...props}
    >
      <path
        fill='#fff'
        d='M22.573 0H7.971C3.936 0 .654 3.283.654 7.318v13.364C.654 24.717 3.936 28 7.971 28h14.602c4.035 0 7.317-3.283 7.317-7.318V7.318C29.89 3.283 26.608 0 22.573 0zM3.235 7.318a4.742 4.742 0 014.736-4.736h14.602a4.742 4.742 0 014.736 4.736v13.364a4.742 4.742 0 01-4.736 4.736H7.971a4.742 4.742 0 01-4.736-4.736V7.318z'
      ></path>
      <path
        fill='#fff'
        d='M15.272 20.806c3.752 0 6.806-3.053 6.806-6.808a6.813 6.813 0 00-6.806-6.807A6.813 6.813 0 008.467 14a6.813 6.813 0 006.805 6.807zm0-11.032A4.23 4.23 0 0119.497 14a4.23 4.23 0 01-4.225 4.226A4.23 4.23 0 0111.048 14a4.23 4.23 0 014.224-4.226zM22.707 8.291a1.835 1.835 0 000-3.668 1.835 1.835 0 000 3.668z'
      ></path>
    </svg>
  )
}

export default InstagramIcon

import React, { SVGProps } from 'react'

function YoutubeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='22'
      fill='none'
      viewBox='0 0 32 22'
      {...props}
    >
      <path
        fill='#fff'
        d='M30.78 6.124c-.186-1.788-.584-3.765-2.051-4.803C27.593.515 26.1.485 24.706.487l-8.84.007-8.502.008C6.18.504 5.029.412 3.93.924c-.944.44-1.683 1.277-2.128 2.208-.617 1.295-.746 2.762-.82 4.194a67.96 67.96 0 00.041 7.83c.12 1.901.427 4.003 1.897 5.216 1.302 1.073 3.141 1.126 4.83 1.128 5.364.005 10.73.01 16.095.013.688.002 1.405-.012 2.106-.088 1.38-.149 2.694-.544 3.58-1.566.894-1.03 1.124-2.464 1.26-3.822a49.04 49.04 0 00-.012-9.913zm-18.016 9.495V6.382l7.996 4.617-7.996 4.62z'
      ></path>
    </svg>
  )
}

export default YoutubeIcon

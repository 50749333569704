import React, { SVGProps } from 'react'

function XIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='31'
      height='28'
      fill='none'
      viewBox='0 0 31 28'
      {...props}
    >
      <path
        fill='#fff'
        d='M.859.02L12.39 15.44.787 27.981H3.4l10.16-10.978 8.208 10.977h8.887l-12.18-16.289L29.277.02h-2.613l-9.355 10.11L9.748.02H.86zm3.84 1.924h4.082l18.03 24.112h-4.082L4.699 1.944z'
      ></path>
    </svg>
  )
}

export default XIcon

import React, { SVGProps } from 'react'

function AndroidIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='22'
      fill='none'
      viewBox='0 0 30 22'
      {...props}
    >
      <path
        fill='#fff'
        d='M24.772 5.837a14.04 14.04 0 00-.29-.285l3.216-3.26a1.058 1.058 0 000-1.484 1.028 1.028 0 00-1.465 0l-3.362 3.408a14.29 14.29 0 00-8.325-2.666 14.29 14.29 0 00-8.318 2.69L2.84.808a1.029 1.029 0 00-1.464 0 1.057 1.057 0 000 1.485l3.244 3.29a14.886 14.886 0 00-3.392 4.929 15.06 15.06 0 00-1.192 5.887V19.4c0 .557.22 1.09.608 1.484a2.06 2.06 0 001.464.616h24.857a2.06 2.06 0 001.464-.616c.388-.393.606-.927.607-1.484v-3.15a14.778 14.778 0 00-1.101-5.64 14.615 14.615 0 00-3.164-4.773zM9.876 16.25c-.307 0-.607-.092-.863-.265a1.57 1.57 0 01-.572-.707 1.595 1.595 0 01.337-1.717 1.535 1.535 0 011.693-.341c.284.119.526.32.697.58a1.59 1.59 0 01-.193 1.989 1.543 1.543 0 01-1.099.46zm9.322 0c-.308 0-.608-.092-.863-.265a1.57 1.57 0 01-.573-.707 1.595 1.595 0 01.337-1.717 1.535 1.535 0 011.693-.341c.284.119.527.32.698.58a1.59 1.59 0 01-.194 1.989 1.543 1.543 0 01-1.098.46z'
      ></path>
    </svg>
  )
}

export default AndroidIcon
